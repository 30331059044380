const constants = {
  // backendUrl: "https://staging.api.gutgereift.at/wp/graphql",
  backendUrl: "https://api.gutgereift.at/wp/graphql",
  imprintUrl: "https://imprint.zewas.at/api/imprints/18?populate=%2A",
  imprintBearer:
    "Bearer 36f36f5ff426c9dbf4f57bc1ab5cd71a5596f0e6a053eb9097bd3362ed12d5ae1b7f8a1908c0d625544c0e47de43665b8112331c436307767d47071ba7d976147973779eaad7488eb4a70040581a272fbf11ffeaeb665d8ceaf4f63d4dcdec85d495aa1fe929c1f7bf7c2befbee8d316170c9739a382a6cda345b53869faa85b",
  nexusLandscapeWidth: 1024,
  nexusPortraitWidth: 768,
  iPhoneLandscapeWidth: 667,
  iPhonePortraitWidth: 420,
};

export default constants;
